import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { AuthService } from '../../core/services/auth.service';
import { SubscriptionService } from '../../core/http/subscription/subscription.service';
import { SharedModule } from '../../shared/shared/shared.module';
import { RecaptchaFormsModule, RecaptchaModule, ReCaptchaV3Service } from 'ng-recaptcha';
import _ from 'lodash';
@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, SharedModule, RecaptchaFormsModule, RecaptchaModule],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss'
})
export class SigninComponent {
  loginForm: any = FormGroup;
  isSubmitted: boolean = false;
  isBetaMessageCrossed: boolean = false;
  showPassword = false;
  password: string = 'password';
  captchaResolved: boolean = false;
  rememberMe: boolean = false;
  @ViewChild('recaptcha') recaptcha!: ReCaptchaV3Service;
  constructor(
    private cookies: CookieService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private commonService: CommonService,
    private subscriptionService: SubscriptionService,
  ) {


  }

  //For form controls value.
  get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@#!%*?&].{8,}$/)]),
      rememberMe: new FormControl(''),
    })
    // if (this.cookies.get('rememberMe')) {
    //   this.rememberMe = true
    // }
    this.isBetaMessageCrossed = this.commonService.isBetaMessageCrossed();
    // if (this.cookies.get('rememberMe')) {
    //   this.loginForm.controls['email'].setValue('email');
    // }
  }

  async signIn() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) return;

    this.commonService.showLoader();
    // const expirationTimeInSeconds = 3600 * 24 * 365;
    // if (this.loginForm.value.rememberMe == true) {
    //   this.cookies.set('rememberMe', 'true', expirationTimeInSeconds)
    //   this.cookies.set('email', this.loginForm.value.email, expirationTimeInSeconds)
    //   this.cookies.set('password', this.loginForm.value.password, expirationTimeInSeconds)
    // } else {
    //   // this.cookies.deleteAll()
    //   this.cookies.delete('email');
    //   this.cookies.delete('password');
    //   this.cookies.delete('rememberMe');
    // }

    let data = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    // return
    this.userService.signIn(data).subscribe((result: any) => {
      this.commonService.showSuccess('Login successful. Redirecting....');
      // this.commonService.showSuccess('') 'Login successful. Redirecting...' });
      // this.loginForm.reset();
      this.authService.setAuthorizationToken(result.token);
      // const expirationTimeInSeconds = 3600 * 24 * 365;
      // this.cookies.set('accessToken', result.token, expirationTimeInSeconds)
      this.userService.me().subscribe((userData: any) => {
        this.authService.setCurrentUser(userData);
        // this.subscriptionService.get().subscribe((result: any) => {
        //   // this.commonService.hideLoader();
        //   let currentSubscription: any = _.pick(result.plan, ['id', 'uuid', 'name', 'programLimit', 'tourLimit',  'cardLimit']);
        //   this.authService.setCurrentSubscription(currentSubscription);
        //   setTimeout(() => {
        //     this.authService.setLoggedIn();
        //     this.commonService.hideLoader();
        //     this.router.navigate(['/dashboard']);
        //   }, 2000)
        // },
        //   (error: any) => {
        //     console.log('error', error.error)
        //     this.commonService.hideLoader();
        //   }
        // )
        setTimeout(() => {
          this.authService.setLoggedIn();
          this.commonService.hideLoader();
          this.router.navigate(['/dashboard']);
        }, 2000)
        this.commonService.hideLoader();
      }, (error: any) => {
        this.commonService.hideLoader();
        this.commonService.showError(error.error.error.message);
      })


    }, (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);
    })
  }

  async emailLowerCase() {
    let email = this.loginForm.value.email ?? 0;
    this.loginForm.controls['email'].setValue(email.toLowerCase());
  }

  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }
  onClick() {
    this.showPassword = !this.showPassword;
    this.password = this.showPassword ? 'text' : 'password';
  }


  onCaptchaResolved(token: any): void {
    if (token) {
      this.captchaResolved = true;
    } else {
      this.captchaResolved = false;
    }
  }
  resetCaptcha(): void {
    // this.recaptcha.reset();
    this.captchaResolved = false;
  }
}
