<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-6 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="mb-3">
                <div class="text-4xl font-medium mb-1">Change Password</div>
            </div>
            <div class="">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid formgrid p-fluid">
                            <form [formGroup]="resetPasswordForm" class="login w-full" autocomplete="off">
                                <div class="field mb-3 col-12">
                                    <label for="password" class="block mb-2">New Password</label>
                                    <input id="password" [type]="password" pInputText class="w-full"
                                        formControlName="password">
                                    <span class="eye-tpoggle" (click)="onClick(1)">
                                        @if(!showPassword){
                                        <i class="pi pi-eye" aria-hidden="true"></i>
                                        }
                                        @if(showPassword){

                                        <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                        }
                                    </span>
                                    @if(isSubmitted && f['password'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['password'].errors['required']){
                                        <small class="p-error block">Password is required</small>
                                        }
                                        @if(f['password'].errors['pattern']){
                                        <small class="p-error block">Password must contain more than eight characters,
                                            at least one uppercase letter, at least one lowercase letter, at least one
                                            number, at least one special character($&#64;#!%*?&)</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="confirmPassword" class="block mb-2">Confirm Password</label>
                                    <input id="confirmPassword" [type]="confirmPassword" pInputText class="w-full"
                                        formControlName="confirmPassword">
                                    <span class="eye-tpoggle" (click)="onClick(2)">
                                        @if(!showConfirmPassword){
                                        <i class="pi pi-eye" aria-hidden="true" ></i>
                                        }
                                        @if(showConfirmPassword){
                                        <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                        }
                                    </span>
                                    @if(isSubmitted && f['confirmPassword'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['confirmPassword'].errors['required']){
                                        <small class="p-error block">Confirm password is required</small>
                                        }
                                        @if(f['confirmPassword'].errors['mustMatch']){
                                        <small class="p-error block">Password doesn't not match</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <p-button label="Submit" styleClass="w-full button-black"
                                        (click)="forgotPasswordFinish()"></p-button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>