import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { UserService } from '../../core/http/user/user.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonService } from '../../core/helpers/common/common.service';
import { isPlatformBrowser } from '@angular/common';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [SharedModule, RouterLink],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent {
  verificationToken: any;
  verified: number = 0
  divVisibility: number = 0
  isBetaMessageCrossed: boolean = false;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.route.queryParams
      .subscribe(params => {
        this.verificationToken = params['token'];
      }
      );
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.verificationToken != undefined && this.verificationToken != '') {
        this.verifyEmail();
      } else {
        this.divVisibility = 1;
      }
    }

  }

  async verifyEmail() {
    this.commonService.showLoader();
    (this.userService.verifyEmail({ verificationToken: this.verificationToken })).subscribe((x: any) => {
      this.verified = 1;
      this.divVisibility = 1;
      this.commonService.hideLoader();
    }, (error: any) => {
      this.divVisibility = 1;
      this.commonService.hideLoader();
    })
  }

  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }
}
