import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-coockie',
  standalone: true,
  imports: [DialogModule, ButtonModule, RouterLink],
  templateUrl: './coockie.component.html',
  styleUrl: './coockie.component.scss'
})
export class CoockieComponent {
  visible: boolean = false;
  constructor(
    private cookies: CookieService,
  ) { }

  ngOnInit(): void {
    let cookieAccepted = this.cookies.get('cookieAccepted');
    if(!cookieAccepted){
      this.visible = true;
    }
  }
  cookieAction(choice: string){
    const expirationTimeInSeconds = 3600*24*365;
    this.cookies.set('cookieAccepted', choice, expirationTimeInSeconds);
    this.visible = false;
  }
}
