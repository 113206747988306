<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-6 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="mb-3">
                <div class="text-4xl font-medium mb-1">Forgot Password</div>
            </div>
            <div class="">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid formgrid p-fluid">
                            <form [formGroup]="fogotPasswordForm" class="login w-full" autocomplete="off">
                                <div class="field mb-3 col-12">
                                    <label for="email" class="block mb-2">Please enter the email address associated with
                                        your account to reset your password</label>
                                    <input placeholder="Enter email" id="email" type="text" pInputText class="w-full"
                                        formControlName="email" (input)="emailLowerCase()">
                                    @if(isSubmitted && f['email'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['email'].errors['required']){
                                        <small class="p-error block">Email is required</small>
                                        }
                                        @if(f['email'].errors['email']){
                                        <small class="p-error block">Invalid email format</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <re-captcha (resolved)="onCaptchaResolved($event)"></re-captcha>
                                    </div>
                                <div class="field mb-3 col-12">
                                    <p-button label="Submit" styleClass="w-full button-black" [disabled]="!captchaResolved"
                                        (click)="forgotPassword()"></p-button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:mb-7">
                <span class="mr-2">Back to login?</span>
                <a class="font-medium no-underline text-blue-500 cursor-pointer" [routerLink]="['/signin']">Log in</a>
            </div>
        </div>
    </div>
</div>