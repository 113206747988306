import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CoockieComponent } from "../coockie/coockie.component";
import { CommonService } from '../../core/helpers/common/common.service';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    imports: [RouterLink, CoockieComponent, SharedModule],
})
export class FooterComponent {
  loader: boolean = false;
  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.commonService.loader$.subscribe(async x => {
      this.loader = x;
    })
  }
}
