<!-- @if(!isBetaMessageCrossed){
<div class="flex align-items-center bg-gray-900 text-white px-3 py-2 w-12 announcement-bar-wl relative "><span>TripZinga
        Beta v1.9 launched. Please <a [routerLink]="['/whats-new']" class="text-decoration-none text-orange-400">click
            here</a> to view the details.</span> <p-button class="ml-auto p-button-rounded" icon="pi pi-times"
        styleClass="p-button-danger icon-btn-sm p-0" (click)="setBetaMessageCrossed()"></p-button></div>
} -->

<div class="align-items-center justify-content-center relative lg:static border-bottom-1"
    style="border-color: #3A547D;">
    <div class="py-3 pb-container-100">
        <div class="flex md:align-items-center md:justify-content-between flex-row">
            <div class="flex align-items-center">
                <!-- <p-button icon="pi pi-arrow-left" styleClass="p-button-rounded p-button-text text-white icon-back-btn mr-3" (click)="goBack()"></p-button> -->
                <span [routerLink]="['/dashboard']" class="cursor-pointer"><img src="assets/logo_transparent.png"
                        alt="Image" height="50" style="float: left;"></span>
            </div>
            <div class="ml-auto flex menu-images align-items-center">
                <span class="block mr-4 mt-1 text-white "><i class="pi pi-question-circle text-xl" [ngClass]="{'text-500': currentUser?.helptipsEnabled != 1}" (click)="changeHelpTipsVisibility()"></i></span>
                <span (click)="openGleap()"><img class="block mr-4" src="assets/sms.svg"
                        style="width: 21px; height: auto; margin-top: 2px;"></span>
                <!-- <span class="ml-2 md:ml-3"><img class="block" src="/assets/cards.svg"></span> -->
                <!-- <span class="" [routerLink]="['/dashboard']"><img src="assets/home_icon.svg"
                        style="width: 23px; height: auto; float: left; margin-top: -2px;"></span> -->
                <span class="flex" (click)="displaySidebar()"><img src="assets/ham_menu.svg"
                        style="width: 23px; height: auto;"></span>
            </div>
        </div>
    </div>
</div>
<p-sidebar styleClass="sidebar-adjust" [(visible)]="showSidebar">
    <ul class="list-none p-0 m-0">
        <li class="border-bottom-1 surface-border flex mb-4">
            <div class="flex mb-4">
                @if(currentUser?.profileImage){
                <img src="{{currentUser?.profileImage}}" height="60" width="60" style="object-fit: cover;"
                    class="border-round-md border-1 surface-border" alt="">
                }
                @else {
                <img src="assets/avatar.png" height="60" width="60" style="object-fit: cover;"
                    class="border-round-md border-1 surface-border" alt="">
                }
                <div class="ml-3">
                    <div class="font-bold text-900">{{currentUser?.name}}</div>
                    <div class="text-sm" style="word-wrap: break-word; width: 205px;">{{currentUser?.email}} </div>
                </div>
            </div>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/dashboard']">
            <a>
                <span class="font-small"> Home</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/profile/edit']">
            <a>
                <span class="font-small">Edit Profile</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/change-password']">
            <a>
                <span class="font-small">Change Password</span>
            </a>
        </li>
        <!-- <li class="mb-4" (click)="hideSidebar()" [routerLink]="['/affiliate-links']">
            <a >
                <span class="font-small" >Support Us</span>
            </a>
        </li> -->
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/hippo-planner']">
            <a>
                <span class="font-small">ZingaPRO</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/manage-players']">
            <a>
                <span class="font-small">Manage Players</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/packings']">
            <a>
                <span class="font-small">Master Packing List</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/plans']">
            <a>
                <span class="font-small">Membership</span>
                @if(currentSubscription?.name?.length)
                {
                <span class="membership-badge">
                    {{currentSubscription?.name}}
                </span>
                }
            </a>
        </li>
        <!-- <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/payment-method']">
            <a>
                <span class="font-small">Manage Payment Method</span>
            </a>
        </li> -->
        <li class="mb-4 cursor-pointer border-bottom-1 surface-border pb-4 " (click)="hideSidebar()" [routerLink]="['/signout']">
            <a>
                <span class="font-small">Logout</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="redirectToBlog()">
            <a>
                <span class="font-small"> Blog</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/contact-us']">
            <a>
                <span class="font-small"> Contact Us</span>
            </a>
        </li>
        <li class="pb-4 mb-4 cursor-pointer border-bottom-1 surface-border" (click)="hideSidebar()"
            [routerLink]="['/faq']">
            <a>
                <span class="font-small"> FAQs</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer " (click)="hideSidebar()" [routerLink]="['/whats-new']">
            <a>
                <span class="font-small"> What's New</span>
            </a>
        </li>
        <li class="mb-4 align-items-center">
            <span class="text-400">Version 2.0</span>
        </li>
        <!-- <li class="mb-4" (click)="hideSidebar()" [routerLink]="['/terms-and-conditions']">
            <a >
                <span class="font-small" > Terms & Conditions</span>
            </a>
        </li>
        <li class="mb-4" (click)="hideSidebar()" [routerLink]="['/privacy-policy']">
            <a >
                <span class="font-small" > Privacy Policy</span>
            </a>
        </li>
        <li class="mb-4 border-bottom-1 surface-border pb-4" (click)="hideSidebar()" [routerLink]="['/cookie-policy']">
            <a >
                <span class="font-small" > Cookie Policy</span>
            </a>
        </li> -->

    </ul>
</p-sidebar>