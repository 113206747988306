import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get the auth token from the service.
    const authToken = this.authService.getAuthorizationToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (authToken) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        withCredentials: true
      })
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      })
    }
    // send cloned request with header to the next handler.
    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          setTimeout(() => {
            this.authService.signOut()
          }, 1000)
        }
        //  if(err.status == 404){
        //   this.router.navigate(['404']);
        //  }
      }

    }));
  }
}


