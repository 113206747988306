import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { HeaderComponent } from "./common/header/header.component";
import { NoauthHeaderComponent } from "./common/noauth-header/noauth-header.component";
import { FooterComponent } from "./common/footer/footer.component";

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [CommonModule, RouterOutlet, HeaderComponent, NoauthHeaderComponent, FooterComponent]
})
export class AppComponent {
  title = 'tripzinga';
  loggedIn = false;
  showNoAuthHeader = false;
  showFooter = false;
  // excludeHeaderFooterForRoutes = environment.excludeHeaderFooterForRoutes.split(',');
  private readonly viewport = inject(ViewportScroller);
  isBrowser: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    
    // console.log('Logged In ',this.loggedIn)
    // this.router.events.subscribe((event) => {
    //   if (!(event instanceof NavigationEnd)) {
    //     // let url = (<NavigationEnd>event).url;
    //     // console.log(url)
    //     // let exactPathname = url.replace("/", "");
    //     // this.showNoAuthHeader = (!this.excludeHeaderFooterForRoutes.includes(exactPathname)) && exactPathname != '' ? true : false;
    //     // this.showFooter = (!this.excludeHeaderFooterForRoutes.includes(exactPathname)) && exactPathname != '' ? true : false;
    //   }
    // });
  }


  ngOnInit() {
    // console.log("ON INIT")
    this.isBrowser = isPlatformBrowser(this.platformId);
    // console.log("PLATFORM BROWSER >> ", this.isBrowser);

    // console.log("AUTH BEFORE SUBSCRIBE >> ", this.loggedIn);
    this.authService.loggedIn$.subscribe(async x => {
      // console.log("AUTH SUBSCRIBE >> ", x)
      this.loggedIn = x;
    });
    this.router.events.subscribe((event) => {
      // console.log("NAVIGATION EVENT")
      // console.log(event)
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.viewport.scrollToPosition([0, 0]);
    });
  }

  ngAfterViewInit()	{
    // console.log("AFTER VIEW INIT")
  }
  
}

