import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { ComparePassword } from '../../core/helpers/validators/validator';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, SharedModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  resetPasswordForm: any = FormGroup;
  isSubmitted: boolean = false;
  verificationToken: any;
  isBetaMessageCrossed: boolean = false;
  showPassword = false;
  showConfirmPassword = false;
  password: string = 'password';
  confirmPassword: string = 'password';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService
  ) {


    this.route.queryParams
      .subscribe(params => {
        this.verificationToken = params['token'];
      }
      );
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@#!%*?&].{8,}$/)]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
      { validator: ComparePassword('password', 'confirmPassword') }
    )
  }

  get f() { return this.resetPasswordForm.controls; }

  async forgotPasswordFinish() {
    this.isSubmitted = true;
    if (this.resetPasswordForm.invalid) return;
    this.commonService.showLoader();
    let data = {
      password: this.resetPasswordForm.value.password,
      verificationToken: this.verificationToken
    };
    (this.userService.forgotPasswordFinish(data)).subscribe((result: any) => {
      this.commonService.showSuccess('Your password has been reseted successfully.')
      this.resetPasswordForm.reset();
      this.isSubmitted = false;
      setTimeout(() => {
        this.commonService.hideLoader();
        this.router.navigate(['/signin']);
      }, 2000)
    }, (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);
    })
  }

  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }
  onClick(id: number) {
    if (id == 1) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showConfirmPassword = !this.showConfirmPassword;
      this.confirmPassword = this.showConfirmPassword ? 'text' : 'password';
    }
  }
}
