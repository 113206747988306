import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../shared/shared/shared.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, SharedModule, RecaptchaFormsModule, RecaptchaModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  fogotPasswordForm: any = FormGroup;
  isSubmitted: boolean = false;
  isBetaMessageCrossed: boolean = false;
  captchaResolved: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private commonService: CommonService
  ) {


  }

  ngOnInit(): void {
    this.fogotPasswordForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    })
    this.isBetaMessageCrossed = this.commonService.isBetaMessageCrossed();
  }

  get f() { return this.fogotPasswordForm.controls; }

  async forgotPassword() {
    this.isSubmitted = true;
    if (this.fogotPasswordForm.invalid) return;
    this.commonService.showLoader();
    let data = {
      email: this.fogotPasswordForm.value.email,
    };
    (this.userService.forgotPasswordStart(data)).subscribe((result: any) => {
      this.commonService.showSuccess('An email has been sent to the supplied email address. Please check yor both inbox and spam folders. Follow the instruction to reset your password.')
      this.isSubmitted = false;
      this.fogotPasswordForm.reset();
      this.commonService.hideLoader();
    }, (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);
    })
  }

  async emailLowerCase() {
    let email = this.fogotPasswordForm.value.email ?? 0;
    this.fogotPasswordForm.controls['email'].setValue(email.toLowerCase());
  }

  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }

  onCaptchaResolved(token: any): void {
    if (token) {
      this.captchaResolved = true;
    } else {
      this.captchaResolved = false;
    }
  }
  
}
