import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from '../../core/helpers/common/common.service';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../shared/shared/shared.module';
import { isPlatformBrowser } from '@angular/common';
declare var Gleap: any;

@Component({
  selector: 'app-noauth-header',
  standalone: true,
  imports: [RouterLink, SharedModule],
  templateUrl: './noauth-header.component.html',
  styleUrl: './noauth-header.component.scss'
})
export class NoauthHeaderComponent {
  showSidebar: boolean = false
  isBetaMessageCrossed: boolean = false;

  constructor(
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.isBetaMessageCrossed = this.commonService.isBetaMessageCrossed();
  }

  displaySidebar(){
    this.showSidebar = true
  }

  hideSidebar(){
    this.showSidebar = false
  }

  setBetaMessageCrossed(){
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }

  redirectToBlog(){
    // window.location.href = 'https://blog.pointhippo.com/';
    window.open('https://blog.tripzinga.com/');
  }

  openGleap(){
    if(isPlatformBrowser(this.platformId)){
      // console.log(Gleap.getInstance())
      Gleap.open();
    }
    
  }
}
