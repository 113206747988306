@if (loggedIn && isBrowser) {
    <app-header></app-header>
}
@if (!loggedIn && isBrowser) {
    <app-noauth-header></app-noauth-header>
}

<router-outlet></router-outlet>
@if (isBrowser) {
    <app-footer></app-footer>
}


