<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-6 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="">
                <div class="grid">
                    <div class="col-12">
                        <div class="formgrid p-fluid">
                            <h3 class="text-danger m-0 mb-3 text-3xl font-medium">Congratulations!</h3>
                            <p class="text-xl m-0 mb-3">You have successfully signed up. To activate your account please
                                check the verification email we have sent you.</p>
                            <div class="flex align-items-center gap-2">
                                <span class="text-xl custom-border">If you do not see the verification email, please check your
                                    spam folder.</span>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p-button styleClass="w-full" label="Back to Log In" [routerLink]="['/signin']"></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>