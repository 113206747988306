<!-- @if (!isBetaMessageCrossed) {
    <div class="flex align-items-center bg-gray-900 text-white px-3 py-2 w-12 announcement-bar-wl relative "><span>TripZinga Beta v1.9 launched. Please <a [routerLink]="['/whats-new']" class="text-decoration-none text-orange-400">click here</a> to view the details.</span> <p-button class="ml-auto p-button-rounded" icon="pi pi-times" styleClass="p-button-danger icon-btn-sm p-0" (click)="setBetaMessageCrossed()"></p-button></div>
} -->

<div class="align-items-center justify-content-center relative lg:static border-bottom-1" style="border-color: #3A547D;">
    <div class="pb-container-100 header-margin">
        <div class="flex md:align-items-center md:justify-content-between flex-row">
            <div class="flex align-items-center">
                <!-- <p-button icon="pi pi-arrow-left" styleClass="p-button-rounded p-button-text text-white icon-back-btn mr-3" (click)="goBack()"></p-button> -->
                <span [routerLink]="['/']" class="cursor-pointer"><img src="assets/logo_transparent.png" alt="Image" height="50" style="float: left;"></span>
            </div>
            <div class="ml-auto flex menu-images align-items-center">
                <!-- <span [routerLink]="['/trips']"><img class="block" src="/assets/points.svg"></span> -->
                <!-- <span class="ml-2 md:ml-3"><img class="block" src="/assets/cards.svg"></span> -->
                <ul class="list-none p-0 m-0 text-white header-main-menu hidden lg:flex">
                    <li class="cursor-pointer" (click)="hideSidebar()" [routerLink]="['/']">
                        <a>
                            <span class="font-small" > Home</span>
                        </a>
                    </li>
                    <!-- <li class="cursor-pointer" (click)="hideSidebar()" [routerLink]="['/hippo-planner']">
                        <a>
                            <span class="font-small" > Hippo Planner</span>
                        </a>
                    </li> -->
                    <li class="cursor-pointer" (click)="hideSidebar()">
                        <a href="https://blog.tripzinga.com/" style="text-decoration: none;">
                            <span class="font-small" > Blog</span>
                        </a>
                    </li>
                    <li class="cursor-pointer" (click)="hideSidebar()" [routerLink]="['/contact-us']">
                        <a>
                            <span class="font-small" > Contact Us</span>
                        </a>
                    </li>
                    <li class="cursor-pointer" (click)="hideSidebar()" [routerLink]="['/faq']">
                        <a>
                            <span class="font-small" > FAQs</span>
                        </a>
                    </li>
                </ul>
                <!-- <span class="" [routerLink]="['/signin']"><img src="assets/home_icon.svg" style="width: 23px; height: auto; float: left;"></span> -->
                <span class="flex lg:hidden" (click)="openGleap()"><img class="block mr-3 mt-1" src="assets/sms.svg"  style="width: 21px; height: auto;"></span>
                <span class="ml-3 flex lg:hidden" (click)="displaySidebar()"><img src="assets/ham_menu.svg" style="width: 23px; height: auto;"></span>
                <p-button class="mx-3 header-button hidden md:block" [routerLink]="['/signin']" label="Log In"></p-button>
                <p-button class="header-button hidden md:block" [routerLink]="['/signup']" label="Sign Up"></p-button>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="showSidebar">
    <ul class="list-none p-0 m-0">
        <!-- <li class="mb-4" (click)="hideSidebar()" [routerLink]="['/affiliate-links']">
            <a class="cursor-pointer" >
                <span class="font-small" > Support Us</span>
            </a>
        </li> -->
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/']">
            <a>
                <span class="font-small" > Home</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/signin']">
            <a>
                <span class="font-small" > Log In</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer " (click)="hideSidebar()" [routerLink]="['/signup']">
            <a>
                <span class="font-small" > Sign Up </span>
            </a>
        </li>
        <!-- <li class="mb-4 cursor-pointer border-bottom-1 surface-border pb-4"  (click)="hideSidebar()" [routerLink]="['/hippo-planner']">
            <a >
                <span class="font-small" >Hippo Planner</span>
            </a>
        </li> -->
        <li class="mb-4 cursor-pointer" (click)="redirectToBlog()">
            <a>
                <span class="font-small" > Blog</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer" (click)="hideSidebar()" [routerLink]="['/contact-us']">
            <a>
                <span class="font-small" > Contact Us</span>
            </a>
        </li>
        <li class="pb-4 mb-4 cursor-pointer border-bottom-1 surface-border" (click)="hideSidebar()" [routerLink]="['/faq']">
            <a>
                <span class="font-small" > FAQs</span>
            </a>
        </li>
        <li class="mb-4 cursor-pointer " (click)="hideSidebar()" [routerLink]="['/whats-new']">
            <a>
                <span class="font-small"> What's New</span>
            </a>
        </li>
        <li class="mb-4 align-items-center">
            <span class="text-400">Version 2.0</span>
        </li>
    </ul>
</p-sidebar>
