import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
// import Gleap from 'gleap';
// Gleap.initialize("r5bg0DKRw3bDYEE7UGUW0jkuSKHgVfC2");
// Gleap.on("open", () => {
//   console.log("Gleap did open.");
// });

// Gleap.on("close", () => {
//   console.log("Gleap did close.");
// });
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
