import { Routes } from '@angular/router';
import { SigninComponent } from './authentication/signin/signin.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { inject } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { SignupFinishComponent } from './authentication/signup-finish/signup-finish.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { P404Component } from './common/p404/p404.component';
import { SignoutComponent } from './authentication/signout/signout.component';


export const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./misc/misc.route').then((m) => m.routes),
    },
    {
        path: 'signin',
        component: SigninComponent
    },
    {
        path: 'signout',
        component: SignoutComponent
    },
    {
        path: 'signup',
        component: SignupComponent
    },
    {
        path: 'signup-finish',
        component: SignupFinishComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'verify-email',
        component: VerifyEmailComponent,
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "trips",
        loadChildren: () => import('./tour/tour.routes').then((m) => m.routes),
    },
    {
        path: "profile",
        loadChildren: () => import('./profile/profile.route').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "plans",
        loadChildren: () => import('./plan/plan.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "manage-players",
        loadChildren: () => import('./player/player.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "dashboard",
        loadChildren: () => import('./dashboard/dashboard.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "programs",
        loadChildren: () => import('./program/program.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "cards",
        loadChildren: () => import('./card/card.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "packings",
        loadChildren: () => import('./packing/packing.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: "payment-method",
        loadChildren: () => import('./payment-method/payment-method.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'alerts',
        loadChildren: () => import('./alert/alert.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()]
    },
    {
        path: 'contest',
        loadChildren: () => import('./contest/contest.routes').then((m) => m.routes),
    },
    {
        path: "downloads",
        loadChildren: () => import('./downloads/downloads.routes').then((m) => m.routes),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: '**',
        component: P404Component,
    },
    {
        path: '404',
        component: P404Component,
    },
];
