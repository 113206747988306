import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { MiscService } from '../../core/http/misc/misc.service';
import { ComparePassword } from '../../core/helpers/validators/validator';
import { SharedModule } from '../../shared/shared/shared.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, SharedModule, RecaptchaFormsModule, RecaptchaModule],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent {
  signUpForm: any = FormGroup;
  isSubmitted: boolean = false;
  isBetaMessageCrossed: boolean = false;
  newsletterSubscribed: boolean = true;
  currencies: any[] = [];
  showPassword = false;
  showConfirmPassword = false;
  password: string = 'password';
  confirmPassword: string = 'password';
  captchaResolved: boolean = false;
  showHint: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private commonService: CommonService,
    private miscService: MiscService
  ) {
    this.getCurrencies();
  }
  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@#!%*?&].{8,}$/)]],
      confirmPassword: ['', Validators.required],
      termsAgreed: [true, [Validators.required]],
      newsletterSubscribed: [''],
      currencyId: ['', [Validators.required]],
    }, { validator: ComparePassword('password', 'confirmPassword') });

    this.isBetaMessageCrossed = this.commonService.isBetaMessageCrossed();
    this.signUpForm.controls['currencyId'].setValue(20);
  }
  get f() { return this.signUpForm.controls; }

  async signup() {
    this.isSubmitted = true;
    if (this.signUpForm.invalid || this.signUpForm.value.termsAgreed == false) return;
    this.commonService.showLoader();
    this.userService.checkUsernameAvailability({userName: this.signUpForm.value.userName}).subscribe((result: any) => {
      // this.commonService.showSuccess(result.message);
      if (result.message) {
        let data = {
          name: this.signUpForm.value.name,
          email: this.signUpForm.value.email,
          userName: this.signUpForm.value.userName,
          password: this.signUpForm.value.password,
          newsletterSubscribed: this.signUpForm.value.newsletterSubscribed ? 1 : 0,
          currencyId: this.signUpForm.value.currencyId
        };
  
        this.userService.signUp(data).subscribe((result: any) => {
          this.commonService.showSuccess('Signup successful.');
          setTimeout(() => {
            this.commonService.hideLoader();
            this.router.navigate(['/signup-finish']);
          }, 2000);
        }, (error: any) => {
          this.commonService.hideLoader();
          this.commonService.showError(error.error.error.message);
        });
      } else {
        this.commonService.hideLoader();
        this.commonService.showError(result.error.message);
      }
    }, (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);
    });
  }

  async emailLowerCase() {
    let email = this.signUpForm.value.email ?? 0;
    this.signUpForm.controls['email'].setValue(email.toLowerCase());
  }

  userNameLowerCase() {
    let userName = this.signUpForm.value.userName ?? 0;
    this.signUpForm.controls['userName'].setValue(userName.toLowerCase());
  }
  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }

  getCurrencies() {
    (this.miscService.getCurrencies()).subscribe((result: any) => {
      this.currencies = result.data      
    }, (error: any) => {
    })
  }
  onClick(id: number) {
    if (id == 1) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showConfirmPassword = !this.showConfirmPassword;
      this.confirmPassword = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  onCaptchaResolved(token: any): void {
    if (token) {
      this.captchaResolved = true;
    } else {
      this.captchaResolved = false;
    }
  }
  
}
