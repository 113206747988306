<div class="align-items-center justify-content-center relative lg:static border-top-1 " style="border-color: #3A547D;">
    <div class="py-3 pb-container-100 border-bottom-1" style="border-color: #3A547D;">
        <ul class="list-none p-0 m-0 text-white flex footer-main-menu justify-content-center md:justify-content-start">
            <li [routerLink]="['/terms-and-conditions']">
                <a class="cursor-pointer">
                    <span class="font-small"> Terms & Conditions</span>
                </a>
            </li>
            <li [routerLink]="['/privacy-policy']">
                <a class="cursor-pointer">
                    <span class="font-small"> Privacy Policy</span>
                </a>
            </li>
            <li [routerLink]="['/cookie-policy']">
                <a class="cursor-pointer">
                    <span class="font-small"> Cookie Policy</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="py-4 pb-container-100">
        <div class="flex md:align-items-center md:justify-content-between flex-row">
            <div class="flex align-items-center text-white mr-5">
                <span>&copy; 2024 Milestone Xperiences. All rights reserved.</span>
            </div>
            <div class="ml-auto flex menu-images">
                <a target="_blank" class="cursor-pointer" href="https://facebook.com/tripzinga">
                    <!-- <i class="pi pi-facebook text-white text-2xl"></i> -->
                    <img src="assets/facebook.svg" width="24" height="24" class="block">
                </a>
                <!-- <a target="_blank" class="cursor-pointer ml-3" href="">
                    <i class="pi pi-twitter text-white text-xl"></i>
                </a>
                <a target="_blank" class="cursor-pointer ml-3" href="">
                    <i class="pi pi-instagram text-white text-xl"></i>
                </a> -->
            </div>
        </div>
    </div>
</div>
<app-coockie></app-coockie>
<p-toast position="top-right"></p-toast>
@if(loader == true){
<div class="progressContainer">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>
}