<div class="pb-container">
    <div class="text-white text-2xl my-3 font-medium">Change Password</div>
    <div class="mt-3 bg-white p-3 rounded-top">
        <div class="grid">
            <div class="col-12 lg:col-5">
                <div class="grid formgrid p-fluid">
                    <form action="" [formGroup]="changePswForm" class="mx-auto md:ml-0 w-full" autocomplete="off">
                        <div class="field mb-3 col-12">
                            <label for="oldPassword" class="mb-2">Current Password</label>
                            <input id="oldPassword" [type]="oldPassword" pInputText formControlName="oldPassword">
                            <span class="eye-tpoggle" (click)="onClick(1)">
                                @if(!showOldPassword){
                                <i class="pi pi-eye" aria-hidden="true"></i>
                                }
                                @if(showOldPassword){
                                <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                }
                            </span>
                            @if(isSubmitted && f['oldPassword'].errors){
                            <div class="invalid-feedback mt-1">
                                @if(f['oldPassword'].errors['required']){
                                <small class="p-error block">Current password is required</small>
                                }
                            </div>
                            }
                        </div>
                        <div class="field mb-3 col-12">
                            <label for="newPassword" class="mb-2">New Password</label>
                            <input id="newPassword" [type]="password" pInputText formControlName="newPassword">
                            <span class="eye-tpoggle" (click)="onClick(2)">
                                @if(!showPassword){
                                <i class="pi pi-eye" aria-hidden="true" ></i>
                                }
                                @if(showPassword){
                                <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                }
                            </span>
                            @if(isSubmitted && f['newPassword'].errors){
                            <div class="invalid-feedback mt-1">
                                @if(f['newPassword'].errors['required']){
                                <small class="p-error block">New password is required</small>
                                }
                                @if(f['newPassword'].errors['pattern']){
                                <small class="p-error block">Password must contain more than eight characters, at least
                                    one uppercase letter, at least one lowercase letter, at least one number, at least
                                    one special character($&#64;#!%*?&)</small>
                                }
                            </div>
                            }
                        </div>
                        <div class="field mb-3 col-12">
                            <label for="confirmPassword" class="mb-2">Confirm Password</label>
                            <input id="confirmPassword" [type]="confirmPassword" pInputText
                                formControlName="confirmPassword">
                            <span class="eye-tpoggle" (click)="onClick(3)">
                                @if(!showConfirmPassword){
                                <i class="pi pi-eye" aria-hidden="true"></i>
                                }
                                @if(showConfirmPassword){
                                <i class="pi pi-eye-slash" aria-hidden="true" ></i>
                                }
                            </span>
                            @if(isSubmitted && f['confirmPassword'].errors){
                                <div class="invalid-feedback mt-1">
                                    @if(f['confirmPassword'].errors['required']){
                                    <small class="p-error block">Confirm Password is required</small>
                                    }
                                    @if(f['confirmPassword'].errors['mustMatch']){
                                    <small class="p-error block">Password doesn't not match</small>
                                    }
                                </div>
                                }
                        </div>
                        <div class="field mb-3 col-12">
                            <p-button label="Update" styleClass="w-full button-black"
                                (click)="updatePassword()"></p-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>