<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-4 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="mb-3">
                <div class="text-4xl font-medium mb-1">Log In</div>
            </div>
            <div class="">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid formgrid p-fluid">
                            <form [formGroup]="loginForm" class="login w-full" autocomplete="off">
                                <div class="field mb-3 col-12">
                                    <label for="email" class="block mb-2">Email</label>
                                    <input id="email" type="text" pInputText class="w-full" formControlName="email"
                                        (input)="emailLowerCase()">
                                    @if((isSubmitted || f['email'].touched || f['email'].dirty) && f['email'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['email'].errors['required']){
                                        <small class="p-error block">Email is required</small>
                                        }
                                        @if(f['email'].errors['email']){
                                        <small class="p-error block">Invalid email format</small>
                                        }
                                    </div>
                                    }

                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="password" class="block mb-2">Password</label>
                                    <input id="password" [type]="password" pInputText class="w-full"
                                        formControlName="password">
                                    <span class="eye-tpoggle" (click)="onClick()">
                                        @if(!showPassword){
                                        <i class="pi pi-eye" aria-hidden="true"></i>
                                        }
                                        @if(showPassword){
                                        <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                        }
                                    </span>
                                    @if((isSubmitted || f['password'].touched || f['password'].dirty) && f['password'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['password'].errors['required']){
                                        <small class="p-error block">Password is required</small>
                                        }
                                        @if(f['password'].errors['pattern']){
                                        <small class="p-error block">Password must contain more than eight characters,
                                            at least one uppercase letter, at least one lowercase letter, at least one
                                            number, at least one special character($&#64;#!%*?&)</small>
                                        }
                                    </div>
                                    }

                                </div>
                                <div class="field mb-3 col-12">
                                    <re-captcha (resolved)="onCaptchaResolved($event)"
                                        (expired)="resetCaptcha()"></re-captcha>
                                </div>
                                <div class="field mb-3 col-12">
                                    <p-button label="Log In" styleClass="w-full button-black"
                                        [disabled]="!captchaResolved" (click)="signIn()"></p-button>
                                </div>

                                <!-- checkbox -->
                                <div class="flex align-items-center justify-content-between field mb-3 col-12">
                                    <!-- <div class="flex align-items-center">
                                        <p-checkbox id="rememberMe" styleClass="mr-2" [binary]="true"
                                            [(ngModel)]="rememberMe" formControlName="rememberMe"></p-checkbox>
                                        <label for="remeberMe" class="">Remember me</label>
                                    </div> -->
                                    <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                                        [routerLink]="['/forgot-password']">Forgot password?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:mb-7">
                <span class="mr-2">Not registered yet?</span>
                <a class="font-medium no-underline text-blue-500 cursor-pointer" [routerLink]="['/signup']">Create an
                    account</a>
            </div>
        </div>
    </div>
</div>