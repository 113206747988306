import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
@Component({
  selector: 'app-signout',
  standalone: true,
  imports: [],
  templateUrl: './signout.component.html',
  styleUrl: './signout.component.scss'
})
export class SignoutComponent {

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userService: UserService
  ) {
    this.signOut();
  }
  signOut(){
    this.commonService.showLoader();
    this.userService.signOut().subscribe(response => {
      
      this.authService.setCurrentUser(null);
      if(isPlatformBrowser(this.platformId)) localStorage.clear();
      this.authService.setLoggedIn(false);
      this.commonService.hideLoader();
      this.commonService.showSuccess(`Logged out successfully.`);
      this.router.navigate(['']) ;
    },
    (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);
    });
  }
}
