import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
  selector: 'app-p404',
  standalone: true,
  imports: [RouterLink,SharedModule],
  templateUrl: './p404.component.html',
  styleUrl: './p404.component.scss'
})
export class P404Component {

}
