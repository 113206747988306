<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-6 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="mb-3">
                <div class="text-4xl font-medium mb-1">Join TripZinga</div>
                <!-- <div class="text-600 text-xl">Limited slots available</div> -->
            </div>
            <div class="">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid formgrid p-fluid">
                            <form action="" [formGroup]="signUpForm" class="w-full" autocomplete="off">
                                <div class="field mb-3 col-12">
                                    <label for="name" class="block mb-2">Name</label>
                                    <input id="name" type="text" pInputText class="w-full" formControlName="name">
                                    @if((isSubmitted || f['name'].touched) && f['name'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['name'].errors['required']){
                                        <small class="p-error block">Name is required</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="userName" class="block mb-2">Username</label>
                                    <input id="userName" type="text" pInputText class="w-full"
                                        formControlName="userName" (input)="userNameLowerCase()">
                                    @if((isSubmitted || f['userName'].touched || f['userName'].dirty) && f['userName'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['userName'].errors['required']){
                                        <small class="p-error block">Username is required</small>
                                        }
                                        @if(f['userName'].errors['pattern']){
                                        <small class="p-error block">Username can only contain letters and numbers.</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="email" class="block mb-2">Email</label>
                                    <input id="email" type="text" pInputText class="w-full" formControlName="email"
                                        (input)="emailLowerCase()">
                                    @if((isSubmitted || f['email'].touched || f['email'].dirty) && f['email'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['email'].errors['required']){
                                        <small class="p-error block">Email is required</small>
                                        }
                                        @if(f['email'].errors['email']){
                                        <small class="p-error block">Invalid email format</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="password" class="block mb-2">Password</label>
                                    <span class="block mb-2 custom-font-size">
                                        Password must have 1 number, 1 letter (uppercase), 1 letter (lowercase) and
                                        should be at least 8 characters in length.
                                    </span>
                                    <input id="password" [type]="password" pInputText class="w-full"
                                        formControlName="password">
                                    <span class="eye-tpoggle" (click)="onClick(1)">
                                        @if(!showPassword){
                                        <i class="pi pi-eye" aria-hidden="true"></i>
                                        }
                                        @if(showPassword){
                                        <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                        }
                                    </span>
                                    @if((isSubmitted || f['password'].touched || f['password'].dirty) && f['password'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['password'].errors['required']){
                                        <small class="p-error block">Password is required</small>
                                        }
                                        @if(f['password'].errors['pattern']){
                                        <small class="p-error block">Password must contain more than eight characters,
                                            at least one uppercase letter, at least one lowercase letter, at least one
                                            number, at least one special character($&#64;#!%*?&)</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="confirmPassword" class="block mb-2">Confirm password</label>
                                    <input id="confirmPassword" [type]="confirmPassword" pInputText class="w-full"
                                        formControlName="confirmPassword">
                                    <span class="eye-tpoggle" (click)="onClick(2)">
                                        @if(!showConfirmPassword){
                                        <i class="pi pi-eye" aria-hidden="true"></i>
                                        }
                                        @if(showConfirmPassword){
                                        <i class="pi pi-eye-slash" aria-hidden="true"></i>
                                        }
                                    </span>
                                    @if((isSubmitted || f['confirmPassword'].touched || f['confirmPassword'].dirty) && f['confirmPassword'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['confirmPassword'].errors['required']){
                                        <small class="p-error block">Confirm password is required</small>
                                        }
                                        @if(f['confirmPassword'].errors['mustMatch']){
                                        <small class="p-error block">Password doesn't not match</small>
                                        }
                                    </div>


                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <label for="currencyId" class="mb-2">Currency</label>
                                    <p-dropdown [options]="currencies" formControlName="currencyId" optionLabel="name"
                                        optionValue="id" placeholder="Select Currency">
                                    </p-dropdown>
                                    @if((isSubmitted || f['currencyId'].touched) && f['currencyId'].errors){
                                    <div class="invalid-feedback mt-1">
                                        @if(f['currencyId'].errors['required']){
                                        <small class="p-error block">Currency is required</small>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <div class="flex align-items-start">
                                        <p-checkbox inputId="termsAgreed" styleClass="mr-2" [binary]="true"
                                            formControlName="termsAgreed"></p-checkbox>
                                        <label for="termsAgreed" class="font-medium">By signing up you agree to the <a
                                                [routerLink]="['/terms-and-conditions']"
                                                class="no-underline text-blue-500">Terms of Service</a> and <a
                                                [routerLink]="['/privacy-policy']"
                                                class="no-underline text-blue-500">Privacy Policy</a></label>
                                    </div>
                                    @if((isSubmitted || f['termsAgreed'].touched) && (f['termsAgreed'].errors || f['termsAgreed'].value == false)){
                                    <div class="invalid-feedback mt-1">
                                        <small class="p-error block">Please agree with our terms and policy</small>
                                    </div>
                                    }
                                </div>
                                <div class="field mb-3 col-12">
                                    <div class="flex align-items-start">
                                        <p-checkbox inputId="newsletterSubscribed" styleClass="mr-2" [binary]="true"
                                            formControlName="newsletterSubscribed"></p-checkbox>
                                        <label for="newsletterSubscribed" class="font-medium">I agree to receive
                                            TripZinga latest feature updates and blog updates newsletter
                                            (Optional)</label>
                                    </div>
                                </div>
                                <div class="field mb-3 col-12">
                                    <re-captcha (resolved)="onCaptchaResolved($event)"></re-captcha>
                                </div>
                                <div class="field mb-3 col-12">
                                    <p-button label="Sign Up" styleClass="w-full button-black"
                                        [disabled]="!captchaResolved" (click)="signup()"></p-button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:mb-4">
                <span class="mr-2">Already have an account?</span>
                <a class="font-medium no-underline text-blue-500 cursor-pointer" [routerLink]="['/signin']">Log in</a>
            </div>
        </div>

    </div>
</div>