<div class="flex flex-column md:flex-row md:text-white">
    <div class="w-full bg-no-repeat bg-cover sign-left-panel" style="background-image: url('assets/bg.png');"></div>
    <div class="w-full px-4 md:px-7 md:py-6 sign-right-panel">
        <div class="sign-right-panel-inner shadow-2 md:shadow-none">
            <div class="mb-3">
                <div class="text-4xl font-medium">Email Verification</div>
            </div>
            <div class="">
                <div class="grid">
                    <div class="col-12 mt-0 pt-0">
                        <div class="formgrid p-fluid">
                            @if(divVisibility == 1 && verified == 1){
                            <h3 class="text-3xl font-medium text-green-responsive-color m-0 mb-3">Thank You!</h3>
                            <p class="text-xl m-0 mb-3">Your email has been verified successfully.</p>
                            <div class="flex align-items-center gap-2">
                                <span class="text-xl custom-border">Now you can log in to TripZinga and explore all its
                                    features.</span>
                            </div>
                            }
                            @if(divVisibility == 1 && verified == 0){
                            <h3 class="text-danger text-3xl font-medium text-red-600 m-0 mb-3">Error!</h3>
                            <p class="text-xl my-0">This link seems to be expired.</p>
                            }
                            
                        </div>
                        <div class="mt-5 text-center">
                            <p-button styleClass="w-full" label="Back to Log In" [routerLink]="['/signin']"></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>