import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../core/http/user/user.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { ComparePassword } from '../../core/helpers/validators/validator';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../shared/shared/shared.module';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, SharedModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  changePswForm: any = FormGroup;
  isSubmitted: boolean = false;
  showOldPassword: boolean = false;
  showPassword = false;
  showConfirmPassword = false;
  oldPassword: string = 'password';
  password: string = 'password';
  confirmPassword: string = 'password';
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
  ) {



  }

  //For form controls value.
  get f() { return this.changePswForm.controls; }

  ngOnInit(): void {
    this.changePswForm = this.formBuilder.group({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@#!%*?&].{8,}$/)]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
      { validator: ComparePassword('newPassword', 'confirmPassword') }
    )
  }
  async updatePassword() {
    this.isSubmitted = true;
    if (this.changePswForm.invalid) return;
    this.commonService.showLoader();
    let data = {
      oldPassword: this.changePswForm.value.oldPassword,
      newPassword: this.changePswForm.value.newPassword,
    };
    (this.userService.changePassword(data)).subscribe((result: any) => {
      this.commonService.showSuccess('Password changed successfully.');
      this.changePswForm.reset();
      this.isSubmitted = false;
      this.commonService.hideLoader();
    }, (error: any) => {
      this.commonService.hideLoader();
      this.commonService.showError(error.error.error.message);

    })

  }
  onClick(id: number) {
    if (id == 1) {
      this.showOldPassword = !this.showOldPassword;
      this.oldPassword = this.showOldPassword ? 'text' : 'password';
    }
    if (id == 2) {
      this.showPassword = !this.showPassword;
      this.password = this.showPassword ? 'text' : 'password';
    }
    if (id == 3) {
      this.showConfirmPassword = !this.showConfirmPassword;
      this.confirmPassword = this.showConfirmPassword ? 'text' : 'password';
    }
  }
}
