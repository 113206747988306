import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/helpers/common/common.service';
import { Location, isPlatformBrowser } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../shared/shared/shared.module';
import { HelpTipsService } from '../../core/helpers/help-tips/help-tips.service';
import { MiscService } from '../../core/http/misc/misc.service';
import { SubscriptionService } from '../../core/http/subscription/subscription.service';
// import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../core/http/user/user.service';
import _ from 'lodash';

// import Gleap from 'gleap';
// const Gleap = require('gleap');
declare var Gleap: any;

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, SharedModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  // displaySidebar: any
  currentUser: any = {};
  showSidebar: boolean = false
  isBetaMessageCrossed: boolean = false;
  currentSubscription: any = {};
  totalCountOfProgram: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private location: Location,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private helpTipsService: HelpTipsService,
    private miscService: MiscService,
    private subscriptionService: SubscriptionService,
    // private cookieService: CookieService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(async x => {
      this.currentUser = x;
    })
    this.authService.currentSubscription$.subscribe(async x => {
      this.currentSubscription = x;
    })

    this.isBetaMessageCrossed = this.commonService.isBetaMessageCrossed();
    this.getHelpTips();
    this.getCurrentUser()
    this.getSubscriptions();
    // this.userService.subscriptionCount$.subscribe((data: any) => {
    // if (data) this.getSubscriptions();
    // });
  }

  displaySidebar() {
    this.showSidebar = true
  }

  hideSidebar() {
    this.showSidebar = false
  }

  // signOut() {
  //   // this.authService.signOut()
  //   this.router.navigate(['/authentication/signout']) 
  // }

  setBetaMessageCrossed() {
    this.commonService.setBetaMessageCrossed();
    this.isBetaMessageCrossed = true;
  }

  goBack() {
    this.location.back();
  }

  redirectToBlog() {
    window.open('https://blog.tripzinga.com/');
  }

  openGleap() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(Gleap.getInstance())
      Gleap.open();
    }

  }

  getHelpTips() {
    this.miscService.getHelpTips().subscribe((result: any) => {
      this.helpTipsService.setHelpTips(result);
    }, (error: any) => {

    })
  }

  // getSubscription() {
  //   this.commonService.showLoader();
  //   this.subscriptionService.get().subscribe((result: any) => {
  //     this.commonService.hideLoader();
  //     let subscriptionData = {
  //       name: result.plan.name,
  //       cardLimit: result.plan.cardLimit,
  //       tourLimit: result.plan.tourLimit
  //     }
  //     this.authService.setCurrentSubscription(subscriptionData);
  //   },
  //     (error: any) => {
  //       this.commonService.hideLoader();
  //     });
  // }

  getCurrentUser() {
    this.userService.me().subscribe((userData: any) => {
      this.authService.setCurrentUser(userData);
      this.subscriptionService.get().subscribe((result: any) => {
        let currentSubscription: any = _.pick(result.plan, ['id', 'uuid', 'name', 'programLimit', 'tourLimit', 'cardLimit']);
        this.authService.setCurrentSubscription(currentSubscription);
      },
        (error: any) => {
          console.log('error', error.error)
          // this.commonService.hideLoader();
        }
      )
    }, (error: any) => {
      // this.commonService.hideLoader();
      // this.commonService.showError(error.error.error.message);
    })

  }
  changeHelpTipsVisibility() {
    let data = {
      helptipsEnabled: this.currentUser?.helptipsEnabled == 1 ? 0 : 1
    }
    this.userService.changeHelpTipsVisibility(data).subscribe(response => {
      this.currentUser.helptipsEnabled = data.helptipsEnabled;
      this.authService.setCurrentUser(this.currentUser);
      if (data.helptipsEnabled == 1) {
        this.commonService.showSuccess(`Help mode turned on.`);
      } else {
        this.commonService.showWarn(`Help mode turned Off.`);
      }
    });
  }

  getSubscriptions() {
    this.userService.getSubscription().subscribe((result: any) => {
      // console.log(result);
      this.userService.setSubscriptionCount(result)
      
    },
      (error: any) => {
        console.log('error', error.error)
        // this.commonService.hideLoader();
      })
  }
}
