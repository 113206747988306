<p-dialog class="cookie-modal text-white" [(visible)]="visible" [style]="{width: '25vw'}" position="bottomright" [closable]="false" [showHeader]="false">
    <div class="mt-3 font-medium text-xl text-white">Cookie</div>
    <p class="mb-0 text-white">
        This website uses cookies to ensure you get the best experience.
        <a [routerLink]="['/cookie-policy']" target="_blank" class="block mt-2 text-white font-medium" style="text-decoration: none;">Learn more</a> 
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="cookieAction('true')" label="Okay" [text]="true" styleClass="p-button-text mr-0"></p-button>
        <!-- <button pButton icon="pi pi-check" (click)="cookieAction('true')" label="Okay" class="p-button-text mr-0"></button> -->
    </ng-template>
</p-dialog>