import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-signup-finish',
  standalone: true,
  imports: [ButtonModule, RouterLink],
  templateUrl: './signup-finish.component.html',
  styleUrl: './signup-finish.component.scss'
})
export class SignupFinishComponent {

}
